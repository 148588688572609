/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const UPDATE_MYPROFILE = 'UPDATE_MYPROFILE';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const UPDATE_MYPROFILE_SUCCESS = 'UPDATE_MYPROFILE_SUCCESS';
export const UPDATE_MYPROFILE_ERROR = 'UPDATE_MYPROFILE_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';


/* Profile */
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const USER_PROFILE_UPDATE_SUCCESS= "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_ERROR= "USER_PROFILE_UPDATE_ERROR";
export const USER_PROFILE_DELETE= "USER_PROFILE_DELETE";
export const USER_PROFILE_DELETE_SUCCESS= "USER_PROFILE_DELETE_SUCCESS";
export const USER_PROFILE_DELETE_ERROR= "USER_PROFILE_DELETE_ERROR";
export const ALL_USER_PROFILE= "ALL_USER_PROFILE";
export const ALL_USER_PROFILE_SUCCESS= "ALL_USER_PROFILE_SUCCESS";
export const ALL_USER_PROFILE_ERROR= "ALL_USER_PROFILE_ERROR";
export const PROFILE_TABLE= "PROFILE_TABLE";
export const PROFILE_TABLE_SUCCESS= "PROFILE_TABLE_SUCCESS";
export const PROFILE_TABLE_ERROR= "PROFILE_TABLE_ERROR";
export const PROFILE_EDIT= "PROFILE_EDIT";
export const PROFILE_EDIT_SUCCESS= "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_ERROR= "PROFILE_EDIT_ERROR";
export const PROFILE_DOCUMENT= "PROFILE_DOCUMENT";
export const PROFILE_DOCUMENT_SUCCESS= "PROFILE_DOCUMENT_SUCCESS";
export const PROFILE_DOCUMENT_ERROR= "PROFILE_DOCUMENT_ERROR";
export const PROFILE_TRIP= "PROFILE_TRIP";
export const PROFILE_TRIP_SUCCESS= "PROFILE_TRIP_SUCCESS";
export const PROFILE_TRIP_ERROR= "PROFILE_TRIP_ERROR";

/* Trips */
export const USER_TRIP = "USER_TRIP";
export const USER_TRIP_SUCCESS = "USER_TRIP_SUCCESS";
export const USER_TRIP_ERROR = "USER_TRIP_ERROR";
export const USER_TRIP_UPDATE = "USER_TRIP_UPDATE";
export const USER_TRIP_UPDATE_SUCCESS= "USER_TRIP_UPDATE_SUCCESS";
export const USER_TRIP_UPDATE_ERROR= "USER_TRIP_UPDATE_ERROR";
export const USER_TRIP_DELETE= "USER_TRIP_DELETE";
export const USER_TRIP_DELETE_SUCCESS= "USER_TRIP_DELETE_SUCCESS";
export const USER_TRIP_DELETE_ERROR= "USER_TRIP_DELETE_ERROR";
export const ALL_USER_TRIP= "ALL_USER_TRIP";
export const ALL_USER_TRIP_SUCCESS= "ALL_USER_TRIP_SUCCESS";
export const ALL_USER_TRIP_ERROR= "ALL_USER_TRIP_ERROR";
export const TRIP_TABLE= "TRIP_TABLE";
export const TRIP_TABLE_SUCCESS= "TRIP_TABLE_SUCCESS";
export const TRIP_TABLE_ERROR= "TRIP_TABLE_ERROR";
export const TRIP_EDIT= "TRIP_EDIT";
export const TRIP_EDIT_SUCCESS= "TRIP_EDIT_SUCCESS";
export const TRIP_EDIT_ERROR= "TRIP_EDIT_ERROR";
export const TRIP_DOCUMENT= "TRIP_DOCUMENT";
export const TRIP_DOCUMENT_SUCCESS= "TRIP_DOCUMENT_SUCCESS";
export const TRIP_DOCUMENT_ERROR= "TRIP_DOCUMENT_ERROR";
export const TRIP_PROFILE= "TRIP_PROFILE";
export const TRIP_PROFILE_SUCCESS= "TRIP_PROFILE_SUCCESS";
export const TRIP_PROFILE_ERROR= "TRIP_PROFILE_ERROR";



/* Documents */
export const USER_DOCUMENTS = "USER_DOCUMENTS";
export const USER_DOCUMENTS_SUCCESS = "USER_DOCUMENTS_SUCCESS";
export const USER_DOCUMENTS_ERROR = "USER_DOCUMENTS_ERROR";
export const USER_DOCUMENTS_UPDATE = "USER_DOCUMENTS_UPDATE";
export const USER_DOCUMENTS_UPDATE_SUCCESS= "USER_DOCUMENTS_UPDATE_SUCCESS";
export const USER_DOCUMENTS_UPDATE_ERROR= "USER_DOCUMENTS_UPDATE_ERROR";
export const USER_DOCUMENTS_DELETE= "USER_DOCUMENTS_DELETE";
export const USER_DOCUMENTS_DELETE_SUCCESS= "USER_DOCUMENTS_DELETE_SUCCESS";
export const USER_DOCUMENTS_DELETE_ERROR= "USER_DOCUMENTS_DELETE_ERROR";
export const ALL_USER_DOCUMENTS= "ALL_USER_DOCUMENTS";
export const ALL_USER_DOCUMENTS_SUCCESS= "ALL_USER_DOCUMENTS_SUCCESS";
export const ALL_USER_DOCUMENTS_ERROR= "ALL_USER_DOCUMENTS_ERROR";
export const DOCUMENTS_TABLE= "DOCUMENTS_TABLE";
export const DOCUMENTS_TABLE_SUCCESS= "DOCUMENTS_TABLE_SUCCESS";
export const DOCUMENTS_TABLE_ERROR= "DOCUMENTS_TABLE_ERROR";
export const DOCUMENTS_EDIT= "DOCUMENTS_EDIT";
export const DOCUMENTS_EDIT_SUCCESS= "DOCUMENTS_EDIT_SUCCESS";
export const DOCUMENTS_EDIT_ERROR= "DOCUMENTS_EDIT_ERROR";


/* Request */
export const REQUEST_DATA = "REQUEST_DATA";
export const REQUEST_DATA_SUCCESS = "REQUEST_DATA_SUCCESS";
export const REQUEST_DATA_ERROR = "REQUEST_DATA_ERROR";
export const USER_REQUEST = "USER_REQUEST"
export const USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS"
export const USER_REQUEST_ERROR = "USER_REQUEST_ERROR"
export const REQUEST_TABLE= "REQUEST_TABLE";
export const REQUEST_TABLE_SUCCESS= "REQUEST_TABLE_SUCCESS";
export const REQUEST_TABLE_ERROR= "REQUEST_TABLE_ERROR";
export const USER_REQUEST_DELETE= "USER_REQUEST_DELETE";
export const USER_REQUEST_DELETE_SUCCESS= "USER_REQUEST_DELETE_SUCCESS";
export const USER_REQUEST_DELETE_ERROR= "USER_REQUEST_DELETE_ERROR";


/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
export * from './userProfile/actions';
export * from './userTrip/actions';
export * from './userDocuments/actions';
export * from "./request/actions"

